import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useSelector, useDispatch } from "react-redux";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Alert, AlertTitle, AppBar, Autocomplete, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, NativeSelect, Radio, RadioGroup, Toolbar, Typography } from '@mui/material';
import { AccountCircleRounded, Add, AddCircle, AddCircleOutline, BookOutlined, CheckCircle, Close, CreditCard, CurrencyRupee, DoubleArrow, Edit, History, KeyboardDoubleArrowRight, LibraryBooks, MenuBook, Person, PhoneAndroid } from '@mui/icons-material';
import PrintHeader from '../../PrintHeader';
import PrintFooter from '../../PrintFooter';
import APP_HTTP from '../../../APP_HTTP';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5e86ad',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const PrintDesignTemplate = (props)=> {
    const state = useSelector((state) => state);
    const [school_info,setSchoolInfo] = React.useState({});
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            handleGetSchoolInfo();
        }
    },[])
    const handleGetSchoolInfo = ()=>{
        APP_HTTP.REQUEST('school/get_school_info',{},true,true).then((response) => {
            let resp = response.data;
            let sinfo = resp.response;
            setSchoolInfo(sinfo)
        });
    }
    const handleClosePopup = () =>{
        props.handleCloseDesignPopup();
    }
    
  return (
    <div id="create-inventory">
        <Dialog open={true} fullWidth={false} fullScreen={true}>
        <AppBar position="fixed" component="nav">
            <Toolbar>
                <DialogTitle>Design Print Template</DialogTitle>
            </Toolbar>
            </AppBar>
        <Divider />
        <DialogContent sx={{marginTop:'70px'}}>
            <Grid container spacing={2}>  
                <Grid item xs={12} sm={12} md={4}>

                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                <table style={{pageBreakAfter:'always',boxShadow: '0px 2px 10px 0px #333'}} cellPadding={0} cellSpacing={0}>
                    <PrintHeader header_data={school_info} colSpan={3}/>
                    <tr>
                        <td colSpan={2} style={{padding:'20px 20px 20px 40px'}}>
                            <h2 style={{margin: '0px',color:'red'}}>INVOICE TO</h2>
                            <p style={{margin: '0px',fontSize: '14px',color:'#333'}}>Student Name</p>
                            <p style={{margin: '0px',fontSize: '14px',color:'#333'}}>Father Name</p>
                            <p style={{margin: '0px',fontSize: '14px',color:'#333'}}>Class (Section)</p>
                        </td>
                        
                        <td style={{width:'250px',padding:'20px 40px 20px 20px'}}>
                            <h2 style={{margin: '0px',color:'red'}}>INVOICE</h2>
                            <p style={{margin: '0px',fontSize: '14px',color:'#333'}}>Invoice No. #PYI1</p>
                            <p style={{margin: '0px',fontSize: '14px',color:'#333'}}>Invoice Date : 01 Apr 2025</p>
                            <p style={{margin: '0px',fontSize: '14px',color:'#333'}}></p>
                        </td>
                    </tr>
                    <tbody>
                        <tr>
                            <td colSpan={3} style={{padding:'20px 40px 20px 40px',paddingBottom:'0px'}}>
                                <table style={{width:'100%',borderTopLeftRadius: '14px',borderTopRightRadius: '14px',borderBottomLeftRadius: '14px', overflow: 'hidden', border: '1px solid #d7d7d7'}} cellPadding={0} cellSpacing={0}>
                                    <thead>
                                    <tr>
                                        <th style={{textAlign:'center',fontSize: '14px', color: '#fff', background: 'red',padding: '8px'}}>SNO</th>
                                        <th style={{textAlign:'left',fontSize: '14px', color: '#fff', background: 'red',padding: '8px'}}>PARTICUALRS</th>
                                        <th style={{textAlign:'left',fontSize: '14px', color: '#fff', background: 'red',padding: '8px'}}>REFERENCE</th>
                                        <th  style={{textAlign:'center',fontSize: '14px', color: '#fff', background: 'red',padding: '8px'}}>AMOUNT</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{fontSize:'12px', textAlign:'center',color:'#333',padding: '8px',borderTop:'1px solid #d7d7d7',background:'#fff'}}>1</td>
                                            <td style={{fontSize:'12px',textAlign:'left',color:'#333',padding: '8px',borderTop:'1px solid #d7d7d7',background:'#fff'}}>Monthly Fee</td>
                                            <td style={{fontSize:'12px',textAlign:'left',color:'#333',padding: '8px',borderTop:'1px solid #d7d7d7',background:'#fff'}}>Monthly Fee</td>
                                            <td style={{fontSize:'12px',textAlign:'center',color:'#333',padding: '8px',borderTop:'1px solid #d7d7d7',background:'#fff'}}>1,000</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontSize:'12px', textAlign:'center',color:'#333',padding: '8px',borderTop:'1px solid #d7d7d7',background:'#f9fff9'}}>1</td>
                                            <td style={{fontSize:'12px',textAlign:'left',color:'#333',padding: '8px',borderTop:'1px solid #d7d7d7',background:'#f9fff9'}}>Monthly Fee</td>
                                            <td style={{fontSize:'12px',textAlign:'left',color:'#333',padding: '8px',borderTop:'1px solid #d7d7d7',background:'#f9fff9'}}>Monthly Fee</td>
                                            <td style={{fontSize:'12px',textAlign:'center',color:'#333',padding: '8px',borderTop:'1px solid #d7d7d7',background:'#f9fff9'}}>1,000</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontSize:'12px', textAlign:'center',color:'#333',padding: '8px',borderTop:'1px solid #d7d7d7',background:'#fff'}}>1</td>
                                            <td style={{fontSize:'12px',textAlign:'left',color:'#333',padding: '8px',borderTop:'1px solid #d7d7d7',background:'#fff'}}>Monthly Fee</td>
                                            <td style={{fontSize:'12px',textAlign:'left',color:'#333',padding: '8px',borderTop:'1px solid #d7d7d7',background:'#fff'}}>Monthly Fee</td>
                                            <td style={{fontSize:'12px',textAlign:'center',color:'#333',padding: '8px',borderTop:'1px solid #d7d7d7',background:'#fff'}}>1,000</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontSize:'12px', textAlign:'center',color:'#333',padding: '8px',borderTop:'1px solid #d7d7d7',background:'#f9fff9'}}>1</td>
                                            <td style={{fontSize:'12px',textAlign:'left',color:'#333',padding: '8px',borderTop:'1px solid #d7d7d7',background:'#f9fff9'}}>Monthly Fee</td>
                                            <td style={{fontSize:'12px',textAlign:'left',color:'#333',padding: '8px',borderTop:'1px solid #d7d7d7',background:'#f9fff9'}}>Monthly Fee</td>
                                            <td style={{fontSize:'12px',textAlign:'center',color:'#333',padding: '8px',borderTop:'1px solid #d7d7d7',background:'#f9fff9'}}>1,000</td>
                                        </tr>
                                       
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2} style={{paddingLeft:'40px'}}>
                                <p style={{margin:'0px'}}>This is payment message 1</p>
                                <p style={{margin:'0px'}}>This is payment message 2</p>
                                <p style={{margin:'0px'}}>This is payment message 3</p>
                                <p style={{margin:'0px'}}>This is payment message 4</p>
                            </td>
                            <td style={{paddingRight:'40px'}}>
                                <table style={{width:'100%',borderBottomLeftRadius: '14px',borderBottomRightRadius: '14px', overflow: 'hidden', border: '1px solid #d7d7d7',borderTop:'none',marginBottom:'20px'}} cellPadding={0} cellSpacing={0}>
                                    <thead>
                                        <tr>
                                            <th style={{textAlign:'left',fontSize: '14px', color: '#333',padding: '8px'}}>
                                                Subtotal
                                            </th>
                                            <th style={{textAlign:'center',fontSize: '16px', color: '#333',padding: '8px'}}>
                                                1000
                                            </th>
                                        </tr>
                                        <tr>
                                            <th style={{textAlign:'left',fontSize: '14px', color: '#333',padding: '8px',borderTop:'1px solid #d7d7d7'}}>
                                                Discount
                                            </th>
                                            <th style={{textAlign:'center',fontSize: '16px', color: '#333',padding: '8px',borderTop:'1px solid #d7d7d7'}}>
                                                1000
                                            </th>
                                        </tr>
                                        <tr>
                                            <th style={{textAlign:'left',fontSize: '14px', color: '#fff',padding: '8px',borderTop:'1px solid #d7d7d7',background: '#1bd51b'}}>
                                                Paid
                                            </th>
                                            <th style={{textAlign:'center',fontSize: '16px', color: '#fff',padding: '8px',borderTop:'1px solid #d7d7d7',background: '#1bd51b'}}>
                                                1000
                                            </th>
                                        </tr>
                                        <tr>
                                            <th style={{textAlign:'left',fontSize: '14px', color: '#fff',padding: '8px',borderTop:'1px solid #d7d7d7',background: 'red'}}>
                                                Dues
                                            </th>
                                            <th style={{textAlign:'center',fontSize: '16px', color: '#fff',padding: '8px',borderTop:'1px solid #d7d7d7',background: 'red'}}>
                                                1000
                                            </th>
                                        </tr>
                                    </thead>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={3} style={{padding: '50px 40px',textAlign: 'right',background: '#1bd51b',color: '#fff',fontWeight: 'bold', fontStyle: 'italic', borderTopLeftRadius: '100%'}}>Authorize Signature</td>
                        </tr>
                    </tfoot>
                </table>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button  onClick={handleClosePopup}>Close</Button>
            <Button variant='contained' color='success'  onClick={handleClosePopup}>Save Template</Button>
        </DialogActions>
        </Dialog>
        
    </div>
  );
}
export default PrintDesignTemplate;