import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useSelector, useDispatch } from "react-redux";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Alert, AlertTitle, AppBar, Autocomplete, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, NativeSelect, Radio, RadioGroup, Toolbar, Typography } from '@mui/material';
import LoaderButton from '../LoaderButton';
import { AccountCircleRounded, Add, AddCircle, AddCircleOutline, BookOutlined, CheckCircle, Close, CreditCard, CurrencyRupee, DoubleArrow, Edit, History, KeyboardDoubleArrowRight, LibraryBooks, MenuBook, Person, PhoneAndroid } from '@mui/icons-material';
import AppDropDown from '../AppDropDown';
import APP_HTTP from '../../APP_HTTP';
import AppUtils from '../../AppUtils';
import SessionDropdown from '../settings/session_and_year/SessionDropDown';
import Classes from '../settings/classes/Classes';
import Sections from '../settings/sections/Sections';
import StudentCard from '../students/StudentCard';
import { Textarea } from '@mui/joy';
import BasicDatePicker from '../DatePicker';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5e86ad',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const CreateInventoryPayment = (props)=> {
    const state = useSelector((state) => state);
    
    const [selected_inventory_list,setSelectedInventoryList] = React.useState([]);
    let [contact_info,setContactInfo] = React.useState([]);
    
    const [inventory_list,setInventoryList] = React.useState([]);
    const [is_loading_inventory, setIsLoadingInventory] = React.useState(true);
   
    const [is_saving_payment, setIsSavingPayment] = React.useState(false);
    const [is_searching_student, setIsSearchingStudent] = React.useState(false);
    const [update_id, setUpdateId] = React.useState(0);
    
    const [bill_summary, setBillSummary] = React.useState({sub_total:0,total_discount:0,total_paid_amount:0,total_payable_amount:0,dues_amount:0,Cash:'',Card:'',Online:'',notes:'',payment_date:new Date()});
  
    let [students_list,setStudentList] = React.useState([]);
    let [removed_item,setRemovedItem] = React.useState([]);
  
    let [current_sequence,setCurrentSequence] = React.useState('');
    const payment_modes = state.payment_modes;
    const is_college = state.school_info.school_info.is_college;
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            handleGetInventory();
            if(props.payment_id){
                setUpdateId(props.payment_id);
                APP_HTTP.REQUEST('payment/get_inventory_payment_on_id',{id:props.payment_id},true,true).then((response) => {
                    let resp = response.data;
                    let res = resp.response;
                    
                    
                    if(res.length>0){
                        res = res[0];
                        let sequence = res.payment_sequence;
                        let bsummary = res.bill_summary;
                        let litem = res.list_item;
                        let autocomplete = res.contact_info.autocomplete;
                        setContactInfo(autocomplete);
                        setBillSummary(bsummary);
                        setSelectedInventoryList(litem);
                        setCurrentSequence(sequence);
                    }
                });
            }else{

                APP_HTTP.REQUEST('settings/get_current_sequence',{type:'inventory_payment'},true,true).then((response) => {
                    let resp = response.data;
                    let sequence = resp.response;
                    setCurrentSequence(sequence);
                });
            }
        }
    },[])
    
    const handleGetInventory = () =>{
        setIsLoadingInventory(true);
        APP_HTTP.REQUEST('inventory/get_inventory',{},true,true).then((response) => {
            let resp = response.data.response;
            setInventoryList(resp);
            setIsLoadingInventory(false);
        });
    }
    const handleCloseInventoryPayment = ()=>{
        props.handleCloseInventoryPayment();
    }
  
    const handleSavePayment = ()=>{
        let bsum = {...bill_summary};
        let cash = (bsum.Cash==="")?0:parseFloat(bsum.Cash);
        let online = (bsum.Online==="")?0:parseFloat(bsum.Online);
        let card = (bsum.Card==="")?0:parseFloat(bsum.Card);
        let total_paid = cash + online + card;
        let is_empty = true;
        if(parseFloat(total_paid)>0){
            is_empty = false;
        }
        if(is_empty===true){
          if(document.getElementById('payment-mode-0')){
            document.getElementById('payment-mode-0').focus();
          }
          return false;
        }
        setIsSavingPayment(true);
        APP_HTTP.REQUEST('payment/save_inventory_payment',{update_id:update_id,contact_info:contact_info,list_item:selected_inventory_list,bill_summary:bill_summary,payment_data:payment_modes,removed_item:removed_item},true,true).then((response) => {
            let resp = response.data;
            let id = resp.id;
            window.location.href = '/inventory_payment/view/'+id;
            /* setIsSavingPayment(false);
            handleCloseInventoryPayment();
            window.location.reload(); */
        });
    }
    const handleChangeInputValue = (value) =>{
        if(value==="" || value.length<=3){
            return false;
        }
        setIsSearchingStudent(true);
        APP_HTTP.REQUEST('student/search_student',{search:value,type:'autocomplete',limit:20},true,true,false,true).then((response) => {
            if(response){
                setIsSearchingStudent(false);
                if(response.data){
                    let resp = response.data;
                    if(resp.response){
                        let search_list = resp.response;
                        if(search_list.length>0){
                            setStudentList(search_list);
                        }else{
                            let slist = [{
                                "label": value,
                                "opening_balance": "0",
                                "label2": "",
                                "id": "0",
                                "photo": "",
                                "name": value,
                                "class": "",
                                "section": "",
                                "father_name": "",
                                "roll_number": "",
                                "type": "student"
                            }];
                            setStudentList(slist);
                        }
                    }
                }
            }
          });
    }
    
    const handleSelectInventory = (index) =>{
        let inv_list = [...inventory_list];
        let sel_inv_list = [...selected_inventory_list];
        let selected_inventory = inv_list[index];
        selected_inventory.quantity = 1;
        selected_inventory.item_total = selected_inventory.item_price;
        sel_inv_list.push(selected_inventory);
        setSelectedInventoryList(sel_inv_list);
        handleCalculation(sel_inv_list);
    }
    const handleChangePayItem = (value,index,key) =>{
        if(value===""){
            value = 0;
        }
        let sel_inv_list = [...selected_inventory_list];
        let selected_inventory = sel_inv_list[index];
        let item_price = selected_inventory.item_price;
        let total_price = item_price * value;
        selected_inventory[key] = value;
        selected_inventory.item_total = total_price;
        setSelectedInventoryList(sel_inv_list);
        handleCalculation(sel_inv_list);
    }
    const handleChangeBillSummary = (value,key) =>{
        if(value===""){
            value = 0;
        }
        let bsum = {...bill_summary};
        bsum[key] = value;
        if(key==='total_discount'){
            let tpayable_amt = bill_summary.sub_total;
            tpayable_amt = tpayable_amt - value;
            bsum.total_payable_amount = tpayable_amt;
        }
        let cash = (bsum.Cash==="")?0:parseFloat(bsum.Cash);
        let online = (bsum.Online==="")?0:parseFloat(bsum.Online);
        let card = (bsum.Card==="")?0:parseFloat(bsum.Card);
        let total_paid = cash + online + card;
        bsum.total_paid_amount = total_paid;
        bsum.dues_amount = bsum.total_payable_amount - total_paid;
        setBillSummary(bsum);
    }
    const handleCalculation = (sel_inv_list) =>{
        let tpayable = 0;
        for (let index = 0; index < sel_inv_list.length; index++) {
            const element = sel_inv_list[index];
            tpayable = tpayable +  parseFloat(element.item_total)
        }
        let bsum = {...bill_summary};
        bsum.sub_total = tpayable;
        bsum.total_payable_amount = tpayable;
        bsum.dues_amount = tpayable - bsum.total_paid_amount;
        setBillSummary(bsum);
    }
    const handleRemoveItem = (idx) =>{
        let sel_inv_list = [...selected_inventory_list];
        let ilist = sel_inv_list[idx];
        if(ilist.inventory_payment_list_id!==undefined){
            let rmitems = [...removed_item];
            rmitems.push(ilist);
            setRemovedItem(rmitems);
        }
        
        sel_inv_list.splice(idx,1);
        setSelectedInventoryList(sel_inv_list);
        handleCalculation(sel_inv_list);
    }
    const handleChangeDate = (id,value) =>{
        let bsum = {...bill_summary};
        bsum[id] = value;
        setBillSummary(bsum);
    }
    const handleChangeValue = (value) =>{
        setContactInfo(value);
      }
  return (
    <div id="create-inventory">
        
        <Dialog open={true} fullWidth={false} fullScreen={true}>
        <AppBar position="fixed" component="nav">
            <Toolbar>
                <DialogTitle>Create Inventory Payment</DialogTitle>
            </Toolbar>
            </AppBar>
        <Divider />
        <DialogContent sx={{marginTop:'70px'}}>
            <Grid container spacing={2}>  
            <Grid item xs={12} sm={12} md={3}>
                    
                    <Autocomplete
                        freeSolo
                        value={contact_info}
                        noOptionsText={(is_searching_student===true)?'Searching...':'No Student Found ! Type To Search'}
                        onChange={(event, newValue) => handleChangeValue(newValue)}
                        onInputChange={(event, newInputValue) => handleChangeInputValue(newInputValue)}
                        disablePortal
                        clearIcon={(is_searching_student)?<CircularProgress size="15px" />:<Close fontSize="small" />}
                        id="payee_dropdown"
                        options={students_list}
                        size="small"
                        renderInput={(params) => <TextField {...params} label={'Select Student'} />}
                    />
                    {(contact_info && contact_info.id && parseInt(contact_info.id)===0) &&
                        <Typography style={{fontSize: '12px',color: 'red',textTransform: 'uppercase'}}>Selected student not exist in the system</Typography>
                    }
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                   {/*  <Classes show="dropdown" handleChangeData={handleChangeClass} defaultValue={selected_class}/> */}
                </Grid>
                    <Grid item xs={12} sm={12} md={2}>
              {/*   {(selected_class>0) &&
                    <Sections show="dropdown" handleChangeData={handleChangeSection}  defaultValue={selected_section}/>
                } */}
                    </Grid>
              <Grid item xs={12} sm={12} md={1} style={{textAlign:'right'}}></Grid>
              <Grid item xs={12} sm={12} md={2} style={{textAlign:'right'}}>
                <BasicDatePicker label="Payment Date" size="small" id="payment_date" handleChangeData={handleChangeDate} defaultValue={bill_summary.payment_date}/>
              </Grid>
              <Grid item xs={12} sm={12} md={2} style={{textAlign:'right'}}>
                <Typography variant='h4'>
                    {current_sequence}
                </Typography>
              </Grid>
            </Grid>
            {(contact_info && contact_info.id!==undefined) &&
                    <Grid container spacing={2}  style={{marginTop:'5px'}}>  
                        <Grid item xs={12} sm={12} md={5} spacing={2}>
                            <Box className="custom-scroll" style={{maxHeight:'500px',overflowY:'scroll'}}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>INVENTORY</StyledTableCell>
                                                <StyledTableCell style={{textAlign:'center'}}>AVAILABLE</StyledTableCell>
                                                <StyledTableCell></StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {inventory_list && inventory_list.map((row,index) => (
                                            <StyledTableRow>
                                                <StyledTableCell>
                                                    <Typography>
                                                    {row.item_name} (₹{AppUtils.currency_format(row.item_price)})
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell style={{textAlign:'center'}}>
                                                    <Typography>{row.available_quantity}</Typography>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Button size='small' sx={{fontSize:'9px'}} onClick={()=>handleSelectInventory(index)}>
                                                        <Typography variant='span' style={{marginTop:'3px'}}>Select</Typography> <KeyboardDoubleArrowRight size="medium"/>
                                                    </Button>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={7} spacing={2}>
                            <TableContainer component={Paper}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>INVENTORY</StyledTableCell>
                                            <StyledTableCell style={{textAlign:'center'}}>QUANTITY</StyledTableCell>
                                            <StyledTableCell style={{textAlign:'right'}}>TOTAL</StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {(selected_inventory_list.length===0) &&
                                        <StyledTableRow>
                                            <StyledTableCell style={{textAlign:'center'}}  colSpan={4}><Typography variant='h5'> Please select inventory from the list</Typography></StyledTableCell>
                                        </StyledTableRow>
                                    }
                                    {selected_inventory_list && selected_inventory_list.map((row,index) => (
                                        <StyledTableRow>
                                            <StyledTableCell>
                                                <Typography>
                                                {row.item_name} (₹{AppUtils.currency_format(row.item_price)})
                                                </Typography>
                                            </StyledTableCell>
                                            
                                            <StyledTableCell style={{textAlign:'center'}}>
                                                <Typography>
                                                <TextField
                                                    margin="dense"
                                                    label="Enter Quantity"
                                                    type="text"
                                                    fullWidth
                                                    size='small'
                                                    value={row.quantity}
                                                    style={{width:'150px'}}
                                                    onChange={(e)=>handleChangePayItem(e.target.value,index,'quantity')}
                                                />
                                                </Typography>
                                            </StyledTableCell>
                                            <StyledTableCell style={{textAlign:'right'}}>
                                                <Typography>₹{AppUtils.currency_format(row.item_total)}</Typography>
                                            </StyledTableCell>
                                            <StyledTableCell style={{textAlign:'right'}}>
                                                <Close style={{cursor:'pointer'}} onClick={()=>handleRemoveItem(index)}/>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {(selected_inventory_list.length>0) &&
                            
                        <Grid container spacing={2}  style={{marginTop:'5px'}}>  
                        <Grid item xs={12} sm={12} md={3}>
                        <Box>
                            <TextField
                                margin="dense"
                                label="Discount(₹)"
                                type="text"
                                fullWidth
                                size="small"
                                onChange={(e)=>handleChangeBillSummary(e.target.value,'total_discount')}
                            />
                        </Box>
                        
                            {(payment_modes.map((value,index)=>{
                            return(
                                <Box style={{marginTop:'15px'}}>
                                <TextField
                                id={"payment-mode-"+index}
                                label={value.value}
                                defaultValue={bill_summary[value.value]}
                                fullWidth
                                size='small'
                                onChange={(e)=>handleChangeBillSummary(e.target.value,value.value)}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">
                                        {(value.value==='Cash') &&
                                        <CurrencyRupee />
                                        }
                                        {(value.value==='Online') &&
                                        <PhoneAndroid />
                                        }
                                        {(value.value==='Card') &&
                                        <CreditCard />
                                        }
                                    </InputAdornment>,
                                }}
                                />
                                </Box>
                            )
                            }))}
                            
                        </Grid>
                        
                        <Grid item xs={12} sm={12} md={5} spacing={2}>
                        <Box>
                            <Divider style={{marginTop:'10px', marginBottom:'10px'}}/>
                            <Typography variant='h6'>
                            Sub Total : <Typography variant='span' style={{float:'right',fontSize:'24px',fontWeight:'bold'}}>₹{AppUtils.currency_format(bill_summary.sub_total)}</Typography>
                            </Typography>
                            {(bill_summary.total_discount>0) &&
                            <>
                            <Divider style={{marginTop:'10px', marginBottom:'10px'}}/>
                            <Typography variant='h6'>Discount : <Typography variant='span' style={{float:'right',fontSize:'24px',fontWeight:'bold'}}>₹{AppUtils.currency_format(bill_summary.total_discount)}</Typography></Typography>
                            </>
                            }
                            <Divider style={{marginTop:'10px', marginBottom:'10px'}}/>
                            <Typography variant='h6'>
                            Payable Amount : <Typography variant='span' style={{float:'right',fontSize:'24px',fontWeight:'bold'}}>₹{AppUtils.currency_format(bill_summary.total_payable_amount)}</Typography>
                            </Typography>
                            <Divider style={{marginTop:'10px', marginBottom:'10px'}}/>
                            {(bill_summary.total_paid_amount>0) &&
                            <>
                                
                                <Typography variant='h6'>
                                Paid Amount : <Typography variant='span' style={{float:'right',fontSize:'24px',fontWeight:'bold'}}>₹{AppUtils.currency_format( bill_summary.total_paid_amount )}</Typography>
                                </Typography>
                                <Divider style={{marginTop:'10px', marginBottom:'10px'}}/>
                            </>
                            }
                            {((bill_summary.total_payable_amount - bill_summary.total_paid_amount)>0) &&
                            <>
                                <Typography variant='h6'>
                                Fee Dues : <Typography variant='span' style={{float:'right',fontSize:'24px',fontWeight:'bold',color:((bill_summary.total_payable_amount - bill_summary.total_paid_amount)>0)?'red':'green'}}>₹{AppUtils.currency_format( Math.abs(bill_summary.dues_amount) )}</Typography>
                                </Typography>
                                <Divider style={{marginTop:'10px', marginBottom:'10px'}}/>
                            </>
                            }
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} spacing={2}>
                            <Box style={{marginTop:'15px'}}>
                                <Textarea placeholder="Notes (if any)" minRows={5} onChange={(e)=>handleChangeBillSummary(e.target.value,'notes')} defaultValue={bill_summary['notes']}/>
                            </Box>
                        </Grid>
                        </Grid>    
                        
                        }
                        </Grid>
                    </Grid>
                }
        </DialogContent>
        <DialogActions>
            <Button size='small' onClick={handleCloseInventoryPayment}>Close</Button>
            {(is_saving_payment===false && selected_inventory_list.length>0) &&
                <Button variant='contained' color='success' size='small' onClick={handleSavePayment}>Create Payment</Button>
            }
            {(is_saving_payment===true) &&
                <LoaderButton variant='contained'/>
            }
          
        </DialogActions>
        </Dialog>
        
    </div>
  );
}
export default CreateInventoryPayment;