import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import TableRow from '@mui/material/TableRow';
import { useSelector, useDispatch } from "react-redux";
import PrintHeader from '../../PrintHeader';
import Watermark from '../../WaterMark';
import PrintFooter from '../../PrintFooter';
import AppUtils from '../../../AppUtils';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

let paymentDataInitialState = {
    
}

const PrintYearlyPackage = (props)=> {
    const state = useSelector((state) => state);
    const [total_payable_amount, setTotalPayable] = React.useState(0);
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            let total_price = 0;
            for (let index = 0; index < props.list_item.length; index++) {
              const element = props.list_item[index];
              total_price = total_price + parseFloat(element.price);
            }
            setTotalPayable(total_price);
            setTimeout(() => {
              
              handlePrintPayment();
            }, 100);
        }  
    },[props])
   
   const handlePrintPayment = ()=>{
    if(document.getElementById("print-yearly-package-block")){
        var divContents = document.getElementById("print-yearly-package-block").innerHTML; 
        
      try{
        var a = window.open('', '', 'height=1000,location=no,left=250px'); 
        a.document.open(); 
        a.document.write('<html><head> <meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><meta http-equiv="X-UA-Compatible" content="ie=edge">'); 
        
        let page_settings='@page{size:"A4";margin:0}';
      
          a.document.write('<style type="text/css"><style type="text/css">'+page_settings+'table{font-family:verdana, arial, sans-serif;font-size:16px;width:100%;margin-bottom:1rem;color:#333;border-collapse:collapse}table td,table th{padding:.75rem;vertical-align:top;border:1px solid #333}table th{white-space: nowrap;}h4{margin-top: 0px;margin-bottom: 5px;} td{line-height: 1.9 !important;}</style></style></style>'); 
        
           a.document.write('</head>'); 
    
          a.document.write('<body onload="window.print();" onafterprint="afterPrint()">'); 
           a.document.write('<script>function afterPrint() {if(localStorage.getItem("save_print_new")!==null){} window.close();}</script>');
          
      
        a.document.write(divContents); 
        a.document.write('</body></html>');
        a.document.close();
        
      }catch{}
    }
   }
  return (
    <div id="print-yearly-package-block" style={{display:'none'}}>
       
        <div style={{position:'relative'}}>
            <Watermark/>
            <table style={{pageBreakAfter:'always'}}>
           
            <PrintHeader header_data={props.header_data} colSpan={3}/>

            <tbody>
                <tr>
                    <th style={{textAlign:'left',backgroundColor:'rgb(251 251 251)'}}  colSpan={3}>PARTICULARS LIST</th>
                </tr>
                <tr>
                    <th style={{textAlign:'center',width:'90px'}}>SNO</th>
                    <th style={{textAlign:'left'}}>PARTICUALRS</th>
                    <th style={{textAlign:'right'}}>AMOUNT</th>
                </tr>            
                            {props.list_item && props.list_item.map((value,index)=>{
                               
                            return (
                                <tr>
                                    <td style={{textAlign:'center'}}>
                                    <span>{index+1}</span>
                                    </td>
                                    <td style={{textAlign:'left',textTransform:'capitalize'}}>
                                    {value.particular}
                                    </td>
                                    
                                    <td style={{textAlign:'right'}}>₹{AppUtils.currency_format(value.price)}</td>
                                </tr>
                            )
                        })}
                <tr>
                  <th colSpan={3} style={{textAlign:'right'}}>₹{AppUtils.currency_format(total_payable_amount)}</th>
                </tr>
            </tbody>
            <PrintFooter colSpan={3} type="yearly_package" info={props.header_data.school_info}/>
        </table>
        </div>
          
    </div>
  );
}
export default PrintYearlyPackage;