import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useSelector, useDispatch } from "react-redux";
import { Button, ButtonGroup, Divider, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import APP_HTTP from '../../APP_HTTP';
import { handleConfirmDeletePayment, handleSetPaymentInfo, handleToggleEditPayment } from './Action';
import { handleChangeMenu } from '../../actions/Action';
import AppUtils from '../../AppUtils';
import { useUtils } from '@mui/x-date-pickers/internals';
import DeleteConfirm from '../DeleteConfirm';
import { CreditCard, CurrencyRupee, Edit, PhoneAndroid } from '@mui/icons-material';
import PrintPaymentFormat1 from './PrintPaymentFormat1';
import PrintPaymentFormat2 from './PrintPaymentFormat2';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5e86ad',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

let paymentDataInitialState = {
    
}

const ViewPayment = (props)=> {
    let item_index = 0;
    let [payment_print_format,setPaymentPrintFormat] = React.useState('');
    let [is_print,setIsPrint] = React.useState(false);
    let [is_yearly_package,setYearlyPackage] = React.useState(false);
    let [current_sequence,setCurrentSequence] = React.useState('');
    const [delete_data,setDeleteData] = React.useState({id:0,endpoint:''});
    let [paymentData,setPaymentData] = React.useState(paymentDataInitialState);
    let [companyInfo,setCompanyInfo] = React.useState([]);
    const state = useSelector((state) => state);
     const is_delete_item = state.is_delete_item;
    const dispatch = useDispatch();
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            
            initialized.current = true;
            handleGetPaymentPrintFormat();
            if(props.payment_data){
                let payment_data = props.payment_data;
                
                APP_HTTP.REQUEST('payment/get_payment_on_id',{id:payment_data.payment_id},true,true).then((response) => {
                    let resp = response.data.response;
                    console.log(resp);
                    
                    setPaymentData(resp);
                    let list_item = resp.list_item
                    for (let index = 0; index < list_item.length; index++) {
                      const element = list_item[index];
                      if(parseInt(element.particulars)===-2){
                        setYearlyPackage(true);
                      }
                    }
                    setCurrentSequence(resp.payment_sequence);
                    dispatch(handleSetPaymentInfo(resp));
                    if(localStorage.getItem('is_print')==='true'){
                      localStorage.removeItem('is_print');
                      setTimeout(() => {
                        handlePrintPayment();
                      }, 100);
                    }
                });
            
               
            }
        }
       
    },[props])
    const handleGetPaymentPrintFormat = ()=>{
      APP_HTTP.REQUEST('settings/get_option',{name:'payment_print_format'},true,true).then((response) => {
          let resp = response.data.response;
          if(resp.value!==undefined){
              setPaymentPrintFormat(resp.value);
          }
      });
  }
    const handleDeletePayment = ()=> {
      let paymentValue = paymentData;
      let payment_id = paymentValue.payment_id;
      let delData = {id:payment_id,target:'payment'};
      setDeleteData(delData);
      dispatch(handleConfirmDeletePayment(true));
  }
  const handleEditPayment = ()=>{
    let payment_info = paymentData;
    let payment_id = payment_info.payment_id;
    window.location.href='/payment/edit/'+payment_id;
    /* dispatch(handleChangeMenu('payment','edit/'+payment_id,'Update Payment',false));
    dispatch(handleToggleEditPayment(true));
    dispatch(handleSetPaymentInfo(payment_info)); */
  }
  const handleModifyDeletedStatus = ()=>{
    let pdata = paymentData;
    pdata.status = 0;
    setPaymentData(pdata)
  }
  const handlePrintPayment = ()=>{
    setIsPrint(true);
    setTimeout(() => {
      setIsPrint(false);
    }, 200);
  }
  return (
    <div id="view-profile">
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
        <Grid container spacing={2} className="mrtop-10 mrbtm-30">  
            <Grid item xs={12} sm={12} md={12} spacing={2}>
            <Grid container spacing={2} className="mrtop-10">  
                    <Grid item xs={12} sm={12} md={12} sx={{color: '#3c3c3c',padding: '5px', borderBottom:'1px solid #d7d7d7'}}>
                        <Grid container spacing={2} className="mrtop-10"> 
                            <Grid item xs={12} sm={12} md={3} sx={{textAlign:'left'}}> 
                                <Typography variant='h4' sx={{fontSize:'20px',fontWeight:'bold',color:'gray'}}>{current_sequence} <span style={{background: (paymentData.payment_type_lbl==='RECEIVED')?'green':'red',
    color: 'white',
    fontSize: '10px',
    fontWeight: 'bold',
    padding: '2px'}}>{paymentData.payment_type_lbl}</span>
    {(paymentData.converted_bill_sequence!=='') &&
      <span style={{fontSize:'10px',fontWeight:'bold',marginLeft:'6px'}}>({paymentData.converted_bill_sequence})</span>
    }</Typography>
                                <Typography variant='h4' sx={{fontSize:'15px',color:'gray'}}>Payment On : {paymentData.payment_date_display}</Typography>
                                {(paymentData.username!=='') &&
                                  <Typography variant='h4' sx={{fontSize:'15px',color:'gray'}}>Created By : {paymentData.username}</Typography>
                                }
                               
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}  sx={{textAlign:'center'}}>
                                <Typography variant='h4' sx={{fontSize:'20px',fontWeight:'bold',color:'gray'}}>
                                {(paymentData.contact_info && paymentData.contact_info.staff_sequence) &&
                                 <span>{paymentData.contact_info.staff_sequence} / </span>
                                }
                                <p style={{margin:'0px'}}>
                                  {(paymentData.contact_info && paymentData.contact_info.family_group_lbl)?paymentData.contact_info.family_group_lbl:paymentData.contact_name}  
                                </p>
                                <p style={{margin:'0px',fontSize:'16px'}}> 
                                  {(paymentData.contact_info && paymentData.contact_info.student_sequence)?'Father Name : '+paymentData.contact_info.father_name:''}
                                </p>
                                </Typography>
                                {(paymentData.contact_info) &&
                                <>
                                    {(paymentData.contact_info.roll_number) &&
                                        <Typography variant='h4' sx={{fontSize:'15px',color:'gray',textTransform:'uppercase'}}>
                                            {paymentData.contact_info.roll_number} | {paymentData.contact_info.family_class_lbl}
                                        </Typography>
                                    }
                                  
                                    <Typography variant='h4' sx={{fontSize:'15px',color:'gray'}}>
                                        {(paymentData.contact_info.mobile_number!=="") &&
                                            <span>{paymentData.contact_info.mobile_number}</span>
                                        } 
                                        {(paymentData.contact_info.mobile_number!=="" && paymentData.contact_info.alt_mobile_number!=="") &&
                                            <span> | </span>
                                        } 
                                        {(paymentData.contact_info.alt_mobile_number!=="") &&
                                            <span>{paymentData.contact_info.alt_mobile_number}</span>
                                        } 
                                    </Typography>
                                    <Typography variant='h4' sx={{fontSize:'15px',color:'gray'}}>
                                        {(paymentData.contact_info.locality!=="") &&
                                            <span>{paymentData.contact_info.locality}</span>
                                        } 
                                        {(paymentData.contact_info.city!=="" && paymentData.contact_info.locality!=="") &&
                                            <span>, </span>
                                        } 
                                        {(paymentData.contact_info.city!=="") &&
                                            <span>{paymentData.contact_info.city}</span>
                                        } 
                                        {(paymentData.contact_info.city!=="" && paymentData.contact_info.state!=="") &&
                                            <span>, </span>
                                        } 
                                        {(paymentData.contact_info.state!=="") &&
                                            <span>{paymentData.contact_info.state}</span>
                                        } 
                                        {(paymentData.contact_info.state!=="" && paymentData.contact_info.pincode!=="") &&
                                            <span>, </span>
                                        } 
                                         {(paymentData.contact_info.pincode!=="") &&
                                            <span>{paymentData.contact_info.pincode}</span>
                                        } 
                                    </Typography>
                                
                                </>
                                }
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                            {(paymentData && paymentData.status>0) &&
                               <Box
                               sx={{
                                 display: 'flex',
                                 '& > *': {
                                   m: 1,
                                 },
                                 float:'right'
                               }}
                             >
                              {(paymentData.payment_type==='payment_in') &&
                               <ButtonGroup
                                 aria-label="vertical outlined button group"
                               >
                                  {(AppUtils.has_access(state,'501_payment_in_edit') && paymentData.converted_bill_sequence==='') &&
                                    <Button key="edit" onClick={handleEditPayment}>Edit</Button>
                                  }
                                  {(AppUtils.has_access(state,'501_payment_in_delete')) &&
                                  <Button key="delete" onClick={handleDeletePayment}>Delete</Button>
                                  }
                                  {(AppUtils.has_access(state,'501_payment_in_print')) &&
                                  <Button key="print" onClick={handlePrintPayment}>Print</Button>
                                  }
                               </ButtonGroup>
                                }
                              {(paymentData.payment_type==='payment_out') &&
                               <ButtonGroup
                                 aria-label="vertical outlined button group"
                               >
                                  {(AppUtils.has_access(state,'502_payment_out_edit')) &&
                                    <Button key="edit" onClick={handleEditPayment}>Edit</Button>
                                  }
                                  {(AppUtils.has_access(state,'502_payment_out_delete')) &&
                                  <Button key="delete" onClick={handleDeletePayment}>Delete</Button>
                                  }
                                  {(AppUtils.has_access(state,'502_payment_out_print')) &&
                                  <Button key="print" onClick={handlePrintPayment}>Print</Button>
                                  }
                               </ButtonGroup>
                                }
                              </Box>
                            }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {(paymentData.status===0 || paymentData.status==='0') &&
            <Grid item xs={12} sm={12} md={12} spacing={2} sx={{position:'relative'}}>
              <img src="/Canceled.png" alt="image" height={'200'} style={{position: 'absolute',left: '36%',top: '42%'}}/>
            </Grid>
            }
            <Grid item xs={12} sm={12} md={12} spacing={2}>
                <Grid container spacing={2}>  
                    <Grid item xs={12} sm={12} md={12}>
                    <h3>Particulars List</h3>
                    <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>SNO</StyledTableCell>
                          <StyledTableCell>FEE TYPE</StyledTableCell>
                          <StyledTableCell>REFERENCE</StyledTableCell>
                          {(is_yearly_package===true) &&
                          <>
                            <StyledTableCell>PRICE</StyledTableCell>
                            <StyledTableCell>QUANTITY</StyledTableCell>
                          </>
                          }
                          <StyledTableCell>FEE PAID</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {paymentData.list_item && paymentData.list_item.map((value,index)=>{
                            if(value.amount>0){
                              item_index = item_index+1;
                            }
                            return (
                              <>
                              {(value.amount>0) &&
                                <StyledTableRow>
                                  <StyledTableCell>
                                    <Typography>{item_index}</Typography>
                                  </StyledTableCell>
                                  {(is_yearly_package===false) &&
                                  <StyledTableCell style={{textTransform:'capitalize'}}>
                                    {(value.is_uniform===true) &&
                                    <>{value.particulars_name}</>
                                    }
                                    {(value.is_uniform!==true) &&
                                    <>
                                    {(parseInt(value.particulars)===3) &&
                                      <>
                                        {(parseFloat(value.amount)>0)?value.particulars_name:'Advance Payment'}
                                      </>
                                    }
                                    {(parseInt(value.particulars)!==3) &&
                                    <>
                                    {value.particulars_name} ({value.fee_month})
                                    </>
                                    }
                                    </>
                              }
                                  </StyledTableCell>
                                  }
                                  {(is_yearly_package===true) &&
                                  <StyledTableCell style={{textTransform:'capitalize'}}>
                                    {value.particular_name}
                                  </StyledTableCell>
                                  }
                                  <StyledTableCell>
                                    {(value.is_uniform===true) &&
                                    <>{value.reference_name}</>
                                    }
                                    {(value.is_uniform!==true) &&
                                    <>
                                      {value.reference}
                                    </>
                          }
                                  </StyledTableCell>
                                  {(is_yearly_package===true) &&
                                    <>
                                      <StyledTableCell>{value.fee}</StyledTableCell>
                                      <StyledTableCell>{value.quantity}</StyledTableCell>
                                    </>
                                    }
                                  <StyledTableCell>₹ {AppUtils.currency_format(value.amount)}</StyledTableCell>
                                </StyledTableRow>
                              }
                              </>
                            )
                        })}
                      </TableBody>
                    </Table>
                    </TableContainer>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}><Typography>Remarks : {(paymentData.remarks!=="")?paymentData.remarks:'-'}</Typography></Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                          <TableHead>
                          <StyledTableRow>
                            <StyledTableCell colSpan={2}>Selected Payment Mode</StyledTableCell>
                          </StyledTableRow>
                          </TableHead>
                          <TableBody>
                      {(paymentData.payment_mode_data_display && paymentData.payment_mode_data_display.map((value,index)=>{
                        return(
                          <StyledTableRow>
                          <StyledTableCell>
                            <Typography>{value.name}</Typography>
                          </StyledTableCell>
                          <StyledTableCell><Typography variant='h6' sx={{fontWeight:'bold',color:'gray'}}>₹{AppUtils.currency_format(value.value)}</Typography></StyledTableCell>
                        </StyledTableRow>
                        )
                      }))}
                        </TableBody>
                        </Table>
                      </TableContainer>
                      {(paymentData.advance_payment_info && paymentData.advance_payment_info.length>0) &&
                      <TableContainer component={Paper} style={{marginTop:'10px'}}>
                         <Table aria-label="customized table">
                     <TableHead>
                       <TableRow>
                         <StyledTableCell colSpan={2}>SETTLED ADVANCE PAYMENT</StyledTableCell>
                       </TableRow>
                       <TableRow>       
                           <StyledTableCell>ADVANCE #</StyledTableCell>
                           <StyledTableCell>AMOUNT</StyledTableCell>
                       </TableRow>
                     </TableHead>
                     <TableBody>
                       {paymentData.advance_payment_info && paymentData.advance_payment_info.map((row,index) => (
                           <StyledTableRow key={row.payment_sequence}>
                          
                           <StyledTableCell component="th" scope="row" sx={{cursor:'pointer'}}>
                               <Typography variant='h6'>{row.payment_sequence}</Typography>
                               <Typography variant='p'>{row.payment_date_display}</Typography>
                           </StyledTableCell>
                           
                           <StyledTableCell>₹{AppUtils.currency_format(row.paid_amount)}</StyledTableCell>
                           
                           </StyledTableRow>
                       ))}
                       </TableBody>
                   </Table>
                      </TableContainer>
                      }
                    </Grid>
                    {(paymentData.paid_amount) &&
                    <Grid item xs={12} sm={12} md={4}>
                      <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                      <TableHead>
                        <StyledTableRow>
                          <StyledTableCell colSpan={2}>Payment Summary</StyledTableCell>
                        </StyledTableRow>
                      </TableHead>
                      <TableBody>
                       
                              <StyledTableRow>
                                <StyledTableCell>
                                  <Typography>Payable Amount</Typography>
                                </StyledTableCell>
                                <StyledTableCell><Typography variant='h6' sx={{fontWeight:'bold',color:'gray'}}>₹{AppUtils.currency_format(paymentData.payable_amount)}</Typography></StyledTableCell>
                              </StyledTableRow>
                              {(paymentData.advance_payment>0) &&
                              <>
                              <StyledTableRow>
                                <StyledTableCell>
                                  <Typography>Settled Advance</Typography>
                                </StyledTableCell>
                                <StyledTableCell><Typography variant='h6' sx={{fontWeight:'bold',color:'gray'}}>₹{AppUtils.currency_format(paymentData.advance_payment)}</Typography></StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow>
                                <StyledTableCell>
                                  <Typography>Final Payable</Typography>
                                </StyledTableCell>
                                <StyledTableCell><Typography variant='h6' sx={{fontWeight:'bold',color:'gray'}}>₹{AppUtils.currency_format(paymentData.payable_amount - paymentData.advance_payment)}</Typography></StyledTableCell>
                              </StyledTableRow>
                              </>
                              }
                              <StyledTableRow>
                                <StyledTableCell>
                                  <Typography>Discount</Typography>
                                </StyledTableCell>
                                <StyledTableCell><Typography variant='h6' sx={{fontWeight:'bold',color:'gray'}}>₹{AppUtils.currency_format(paymentData.discount)}</Typography></StyledTableCell>
                              </StyledTableRow>
                          
                              <StyledTableRow>
                                <StyledTableCell>
                                  <Typography>Late Fine</Typography>
                                </StyledTableCell>
                                <StyledTableCell><Typography variant='h6' sx={{fontWeight:'bold',color:'gray'}}>₹{AppUtils.currency_format(paymentData.late_fee)}</Typography></StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow>
                                <StyledTableCell>
                                  <Typography>Paid</Typography>
                                </StyledTableCell>
                                <StyledTableCell><Typography variant='h6' sx={{fontWeight:'bold',color:'gray'}}>₹{AppUtils.currency_format(paymentData.paid_amount)}</Typography></StyledTableCell>
                              </StyledTableRow>
                            {(paymentData.fee_dues<0) &&
                              <StyledTableRow>
                                <StyledTableCell>
                                  <Typography>Advance</Typography>
                                </StyledTableCell>
                                <StyledTableCell><Typography variant='h6' sx={{fontWeight:'bold',color:'gray'}}>₹{AppUtils.currency_format(Math.abs(paymentData.fee_dues))}</Typography></StyledTableCell>
                              </StyledTableRow>
                            }
                            {(paymentData.fee_dues>0) &&
                              <StyledTableRow>
                                <StyledTableCell>
                                  <Typography>Fee Dues</Typography>
                                </StyledTableCell>
                                <StyledTableCell><Typography variant='h6' sx={{fontWeight:'bold',color:'gray'}}>₹{AppUtils.currency_format(paymentData.fee_dues)}</Typography></StyledTableCell>
                              </StyledTableRow>
                            }
                      </TableBody>
                    </Table>
                    </TableContainer>
                    </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
        </Box>
        {(is_delete_item) &&
          <DeleteConfirm delete_data={delete_data} title="Delete Confirmation" handleModifyDeletedStatus={handleModifyDeletedStatus}/>
        }
        {(is_print) &&
        <>
        {(payment_print_format==='' || payment_print_format==='format1') &&
          <PrintPaymentFormat1 payment_data={paymentData} is_yearly_package={is_yearly_package}/>
        }
        {(payment_print_format==='format2') &&
          <PrintPaymentFormat2 payment_data={paymentData} is_yearly_package={is_yearly_package}/>
        }
        </>
        }
    </div>
  );
}
export default ViewPayment;