import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Divider, InputLabel, MenuItem, NativeSelect, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { AddCircleOutline, ArrowRightAlt, Delete, Edit, PlusOneRounded } from '@mui/icons-material';
import { useSelector, useDispatch } from "react-redux";
import { handleConfirmDeleteClass, handleOpenAddClass,handleSetClassList } from './Action.jsx';
import CreateClass from './CreateClass';
import DeleteConfirm from '../../DeleteConfirm.jsx';
import AppDropDown from '../../AppDropDown.jsx';
import APP_HTTP from '../../../APP_HTTP.jsx';
import AppUtils from '../../../AppUtils.jsx';
import { handleSetSectionList } from '../sections/Action.jsx';

const Classes = (props) => {
    const [delete_data,setDeleteData] = React.useState({id:0,endpoint:''});
    const [is_loading,setLoading] = React.useState(true);
    const state = useSelector((state) => state);
  const is_college = state.school_info.school_info.is_college;
    const dispatch = useDispatch();
    const is_open_add_class = state.is_open_add_class;
    const is_delete_item = state.is_delete_item;
    const class_list = state.class_list;
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if(class_list.length===0){
            if (!initialized.current) {
                initialized.current = true;
                APP_HTTP.REQUEST('settings/get_class_list',{},true,true).then((response) => {
                    let resp = response.data;
                    let class_list = resp.response;
                    setLoading(false);
                    dispatch(handleSetClassList(class_list));
                });
            }
        }
    },[dispatch,class_list])

    let lbl_value =(is_college===true)?'Select Course':'Select Class';
    const list_item = [{key:0,value:lbl_value,sections:[]}];
    for (let index = 0; index < class_list.length; index++) {
        const element = class_list[index];
        let cls_item = {key:element.id,value:element.name,sections:element.sections};
        list_item.push(cls_item)
    }
    const [classValue,setClassEdit] = React.useState({id:0,name:'',is_edit:true});
    
    const handleDeleteItem = (id)=> {
        let clsValue = class_list[id];
        let clsId = clsValue.id;
        
        let delData = {id:clsId,index:id,target:'class'};
        setDeleteData(delData);
        dispatch(handleConfirmDeleteClass(true));
    }
    const handleEditItem = (id)=>{
        let clsValue = class_list[id];
        if(clsValue.is_edit===undefined){
            clsValue['is_edit'] = true;
        }else {
            clsValue.is_edit = true;
        }
        if(clsValue.update_id===undefined){
            clsValue['update_id'] = id;
        }else{
            clsValue.update_id = id;
        }
        setClassEdit(clsValue);
        dispatch(handleOpenAddClass(true));
    }
    const openAddClass = () =>{
        setClassEdit({id:0,name:'',is_edit:false});
        dispatch(handleOpenAddClass(true));
    }
    const handleChangeClass = (id,value,secli) =>{
        props.handleChangeData(id,value);
       dispatch(handleSetSectionList(secli));
    }
    const handleChangeClassSequence = (value,index) =>{
        let cls_list = [...class_list];
        cls_list[index].sequence = value;
        dispatch(handleSetClassList(cls_list));
        let cl_id = cls_list[index].id;
        APP_HTTP.REQUEST('settings/update_class_sequence',{sequence:value,id:cl_id},true,true).then((response) => {});
    }
  return (
    <>
    {(props.show==='list') &&
    <>
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Typography variant='h4' sx={{backgroundColor: 'rgba(25, 118, 210, 0.08)',marginTop: '8px', padding: '3px 10px',color:'rgba(0, 0, 0, 0.54)'}}>
                        {(is_college===true)?'Courses':'Classes'}
                        {(AppUtils.has_access(state,'21_class_add')) &&
                        <AddCircleOutline sx={{float:'right',marginTop:'3px',cursor:'pointer'}} fontSize='large' onClick={openAddClass}/>
                        }
                    </Typography>
                </Grid>
                {(AppUtils.has_access(state,'21_class_view')) &&
                <Grid item xs={12} sm={12} md={12} spacing={2} style={{paddingTop:'8px'}}>
                <Divider />
                    <List>
                        {(class_list.map((value,index)=>{
                            let cls_lbl = value.name;
                            if(value.section_label!==""){
                                cls_lbl = cls_lbl+=' ('+value.section_label+')';
                            }
                            return (
                                <>
                                <ListItem key={value.name} disablePadding>
                                    <ListItemButton>
                                        <ListItemText primary={cls_lbl} />
                                        <TextField
                                        autoFocus
                                        margin="dense"
                                        label={'Enter Sequence'}
                                        type="text"
                                        variant="standard"
                                        value={value.sequence}
                                        onChange={(e)=>handleChangeClassSequence(e.target.value,index)}
                                        />
                                        {(AppUtils.has_access(state,'21_class_delete')) &&
                                            <Delete fontSize='small' onClick={() =>handleDeleteItem(index)}/>
                                        }
                                        {(AppUtils.has_access(state,'21_class_edit')) &&
                                            <Edit  fontSize='small' onClick={() =>handleEditItem(index)}/>
                                        }
                                    </ListItemButton>
                                </ListItem>
                                <Divider/>
                            </>
                            )
                        }))}
                        {(is_loading===false && class_list.length===0) &&
                             <ListItem key={"No Record Found"} disablePadding>
                                <ListItemButton>
                                    <ListItemText primary={"No Record Found"} />
                                </ListItemButton>
                            </ListItem>
                        }
                        {(is_loading===true && class_list.length===0) &&
                             <ListItem key={"Loading..."} disablePadding>
                                <ListItemButton>
                                    <ListItemText primary={"Loading..."} />
                                </ListItemButton>
                            </ListItem>
                        }
                    </List>
                
                </Grid>
                }
            </Grid>
        </Box>
       {(is_open_add_class) && 
        <CreateClass classValue={classValue}/>
       }
        {(is_delete_item) &&
        <DeleteConfirm delete_data={delete_data} title="Delete Confirmation" />
       }
    </>
    }
    {(props.show==='dropdown') &&
    <>
        {(list_item.length>0) &&
            <AppDropDown label={(props.label!==undefined)?props.label:(is_college===true)?'Course':'Class'} id={(props.id!==undefined)?props.id:"class"} defaultValue={(props.defaultValue)?parseFloat(props.defaultValue):0} list_item={list_item} size="small" className={props.className} handleChangeClass={handleChangeClass} required={(props.required!==undefined && props.required===true)?true:false}/>
        }
    </>
    }
    </>
  );
}

export default Classes;