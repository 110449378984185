import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useSelector, useDispatch } from "react-redux";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Alert, AlertTitle, AppBar, Autocomplete, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormLabel, InputLabel, Link, MenuItem, NativeSelect, Radio, RadioGroup, Toolbar, Typography } from '@mui/material';
import LoaderButton from '../LoaderButton';
import { AccountCircleRounded, Add, AddCircle, AddCircleOutline, BookOutlined, CheckCircle, Close, CreditCard, CurrencyRupee, DoubleArrow, Edit, History, KeyboardDoubleArrowRight, LibraryBooks, MenuBook, Person, PhoneAndroid } from '@mui/icons-material';
import AppDropDown from '../AppDropDown';
import APP_HTTP from '../../APP_HTTP';
import AppUtils from '../../AppUtils';
import SessionDropdown from '../settings/session_and_year/SessionDropDown';
import Classes from '../settings/classes/Classes';
import Sections from '../settings/sections/Sections';
import StudentCard from '../students/StudentCard';
import { Textarea } from '@mui/joy';
import BasicDatePicker from '../DatePicker';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5e86ad',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const SchoolInventory = (props)=> {
    const state = useSelector((state) => state);
    const [is_open_add_inventory, setIsOpenInventory] = React.useState(false);
    const [is_saving_book, setIsSavingBook] = React.useState(false);
    const [new_inventory_list,setNewInventory] = React.useState([{item_name:'',item_price:'',item_quantity:''}]);
   
    const [inventory_list,setInventoryList] = React.useState([]);
    const [is_loading_inventory, setIsLoadingInventory] = React.useState(true);
    const [is_loading_inventory_payment, setIsInventoryPaymentList] = React.useState(true);
    const [is_loading_inventory_history, setIsLoadingInventoryHistory] = React.useState(true);
    const [is_inventory_history, setIsInventoryHistory] = React.useState(false);
    
    let [inventory_history_list,setInventoryHistory] = React.useState([]);
   
    const is_college = state.school_info.school_info.is_college;
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            handleGetInventory();
        }
    },[])
   
    const handleGetInventory = () =>{
        APP_HTTP.REQUEST('inventory/get_inventory',{},true,true).then((response) => {
            let resp = response.data.response;
            setInventoryList(resp);
            setIsLoadingInventory(false);
        });
    }
    const handleSaveInventory = ()=>{
        setIsSavingBook(true);
        APP_HTTP.REQUEST('inventory/save',{new_inventory_list},true,true).then((response) => {
            setIsSavingBook(false);
            setIsOpenInventory(false);
            handleGetInventory();
        });
    }
    const handleOpenAddInventory = ()=>{
        setIsOpenInventory(true);
    }
    const handleCloseAddInventory = ()=>{
        setIsOpenInventory(false);
        setNewInventory([{item_name:'',item_price:'',item_quantity:''}]);
    }
    const handleAddMoreInventory = () =>{
        let clist = [...new_inventory_list];
        let newcl = {item_name:'',item_price:'',item_quantity:''};
        clist.push(newcl)
        setNewInventory(clist);
    }
    
    const handleChangeItemInfo= (id,value,type) =>{
        let blist  = [...new_inventory_list];
        blist[id][type] = value;
        setNewInventory(blist);
    }
   
   
    const handleGetInventoryHistory = (history_id) =>{
        setInventoryHistory([]);
        setIsLoadingInventoryHistory(true);
        APP_HTTP.REQUEST('inventory/get_inventory_log',{inventory_id:history_id},true,true).then((response) => {
            let resp = response.data.response;
            setInventoryHistory(resp);
            setIsLoadingInventoryHistory(false);
        });
    }
   
  
    const handleEditInventory = (index) =>{
        let clist = [...inventory_list];
        let newcl = clist[index];
        setNewInventory([newcl]);
        setIsOpenInventory(true);
    }
  
    const handleSearchInventory = (value) =>{
        if(value===''){
            handleGetInventory();
        }
        if(value.length<2){
            return false;
        }
        setIsLoadingInventory(true);
        APP_HTTP.REQUEST('inventory/get_inventory',{search:value},true,true,false,true).then((response) => {
            if(response.data && response.data.response){
                let resp = response.data.response;
                setInventoryList(resp);
            }
            setIsLoadingInventory(false);
        });
    }
    const handleShowInventoryHistory = (history_id) =>{
        setIsInventoryHistory(true);
        handleGetInventoryHistory(history_id)
    }
    const handleCloseHistory = ()=>{
        setIsInventoryHistory(false);
    }
  
  return (
    <div id="create-inventory">
        {(AppUtils.has_access(state,'305_inventory_view')) &&
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
        <Grid container spacing={2} className="mrtop-10 mrbtm-30">  
            <Grid item xs={12} sm={12} md={12} spacing={2}>
                <Grid container spacing={2} className="mrtop-10">  
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography variant='h4'>Inventory</Typography>
                        <Typography>Here, You can add inventory, track availablility!</Typography>
                    </Grid> 
                    <Grid item xs={12} sm={12} md={12}>
                        <Divider />
                    </Grid> 
                    <Grid item xs={12} sm={12} md={12}>
                        <Grid container spacing={2} className="mrtop-10">  
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography variant='h4' sx={{backgroundColor: '#5e86ad', padding: '3px 10px',color:'#fff'}}>
                                    Inventory <Button size='small' variant='contained' onClick={handleOpenAddInventory} sx={{float:'right',cursor:'pointer',borderColor: '#fff', marginTop: '5px',background: 'white',color: '#1976d2'}}><AddCircleOutline style={{marginRight:'5px'}}/> Create New Inventory</Button>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} style={{paddingTop:'0px'}}>
                            <TextField
                            margin="dense"
                            id={"search-book-inventory"}
                            label="Type to search Inventory"
                            type="text"
                            fullWidth
                            size='small'
                            onChange={(e)=>handleSearchInventory(e.target.value)}
                        />
                                <TableContainer component={Paper}>
                                    <Table  aria-label="customized table">
                                        <TableHead>
                                        <TableRow>
                                            
                                            <StyledTableCell>Name</StyledTableCell>
                                            <StyledTableCell>Quantity</StyledTableCell>
                                            <StyledTableCell>Price</StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {inventory_list && inventory_list.map((row,index) => (
                                            <StyledTableRow>
                                            <StyledTableCell>
                                                <Typography style={{fontSize:'20px'}}>
                                                {row.item_name}
                                                </Typography>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <Typography style={{fontSize:'20px',fontWeight:'bold'}}><span style={{color:'#1edc1e'}}>{row.item_quantity}</span> / <span style={{color:'red'}}>{row.available_quantity}</span></Typography>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <Typography style={{fontSize:'20px',fontWeight:'bold'}}>{row.item_price}</Typography>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <History style={{marginRight:'10px', cursor:'pointer'}} onClick={()=>handleShowInventoryHistory(row.inventory_id)}/>
                                                <Edit style={{cursor:'pointer'}} onClick={()=>handleEditInventory(index)} fontSize='small'/>
                                            </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                        {(is_loading_inventory===true) &&
                                        <StyledTableCell component="th" scope="row" colSpan={4} sx={{textAlign:'center'}}>
                                            Please wait while loading the list...
                                        </StyledTableCell>
                                        }   
                                        {(is_loading_inventory===false && inventory_list && inventory_list.length===0) &&
                                        <StyledTableCell component="th" scope="row" colSpan={4} sx={{textAlign:'center'}}>
                                            Oops ! No Inventory Found
                                        </StyledTableCell>
                                        }   
                                        </TableBody>
                                    </Table>
                                    </TableContainer>
                               
                            </Grid>
                        </Grid>
                    </Grid> 
                </Grid>
            </Grid>
        </Grid>
        </Box>
        }
        
        {(is_open_add_inventory===true) &&
        <Dialog open={true} maxWidth={'sm'}>
        <DialogTitle>Add Inventory</DialogTitle>
        <DialogContent>
        <Grid container spacing={2} className="mrtop-10">  
       
            {(new_inventory_list.map((value,index)=>{
                return(
                    <>
                    <Grid item xs={12} sm={12} md={4}>
                    <TextField
                        autoFocus={true}
                        margin="dense"
                        id={"item_name_"+index}
                        label="Enter Inventory Name"
                        type="text"
                        fullWidth
                        size='small'
                        value={value.item_name}
                        onChange={(e)=>handleChangeItemInfo(index,e.target.value,'item_name')}
                    />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                    <TextField
                        
                        margin="dense"
                        id={"item_quantity_"+index}
                        label="Enter Quantity"
                        type="text"
                        fullWidth
                        size='small'
                        value={value.item_quantity}
                        onChange={(e)=>handleChangeItemInfo(index,e.target.value,'item_quantity')}
                    />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                    <TextField
                        
                        margin="dense"
                        id={"item_price"+index}
                        label="Enter Item Price"
                        type="text"
                        fullWidth
                        size='small'
                        value={value.item_price}
                        onChange={(e)=>handleChangeItemInfo(index,e.target.value,'item_price')}
                    />
                    </Grid>
                    </>
                )
            }))}
            <Grid item xs={12} sm={12} md={12}>
                <Button size='small' variant='outlined' fullWidth={true} onClick={handleAddMoreInventory}><Add style={{marginRight:'5px'}} /> Add More Inventory</Button>
            </Grid>
        </Grid>
        </DialogContent>
        <DialogActions>
          <Button size='small' onClick={handleCloseAddInventory}>Close</Button>
          {(is_saving_book===false) &&
            <Button variant='contained' color='success' size='small' onClick={handleSaveInventory}>Save</Button>
            }
            {(is_saving_book===true) &&
                <LoaderButton variant='contained'/>
            }
          
        </DialogActions>
        </Dialog>
        }
       
        {(is_inventory_history===true) &&
        <Dialog open={true} fullWidth maxWidth="md">

            {(inventory_history_list.length===0) &&
                <DialogTitle>Inventory Log</DialogTitle>
            }
            {(inventory_history_list.length>0) &&
                <DialogTitle style={{paddingBottom:'0px', borderBottom:'1px solid #d7d7d7'}}>
                    <span style={{fontSize:'20px'}}>Log for {inventory_history_list[0].inventory_info.item_name}</span>
                </DialogTitle>
            }

        <DialogContent>
            <Grid container spacing={2} style={{marginTop:'8px'}}>
                <Grid item xs={12} sm={12} md={12}>
                <TableContainer component={Paper}>
                    <Table  aria-label="customized table">
                        <TableHead>
                        <TableRow>
                            <StyledTableCell>Action</StyledTableCell>
                            <StyledTableCell>Invoice</StyledTableCell>
                            <StyledTableCell style={{textAlign:'center'}}>Last Quantity</StyledTableCell>
                            <StyledTableCell style={{textAlign:'center'}}>Log Quantity</StyledTableCell>
                            <StyledTableCell style={{textAlign:'center'}}>Available Quantity</StyledTableCell>
                            
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {inventory_history_list && inventory_history_list.map((row,index) => (
                            <StyledTableRow>
                            <StyledTableCell>
                                <Typography style={{fontSize:'14px',color:row.color}}>{row.log_message}</Typography>
                                <Typography style={{fontSize:'12px'}}>
                                    {row.date_display}
                                </Typography>
                            </StyledTableCell>
                            <StyledTableCell>
                                {(AppUtils.has_access(state,'5_payments_view')===true && row.invoice_info.inventory_payment_id>0) &&
                                    <Link href={'/inventory_payment/view/'+row.invoice_info.inventory_payment_id} target='_blank'>{(row.invoice_info && row.invoice_info.payment_sequence)?row.invoice_info.payment_sequence:'-'}</Link>
                                }
                                {(AppUtils.has_access(state,'5_payments_view')===false) &&
                                    <Typography style={{fontSize:'14px'}}>{(row.invoice_info && row.invoice_info.payment_sequence)?row.invoice_info.payment_sequence:'-'}</Typography>
                                }
                            </StyledTableCell>
                            <StyledTableCell style={{textAlign:'center'}}>
                                <Typography style={{fontSize:'16px',color:row.color}}>{row.last_quantity}</Typography>
                            </StyledTableCell>
                            <StyledTableCell style={{textAlign:'center'}}>
                                <Typography style={{fontSize:'16px',color:row.color}}>{row.quantity}</Typography>
                            </StyledTableCell>
                            <StyledTableCell style={{textAlign:'center'}}>
                                <Typography style={{fontSize:'16px',color:row.color}}>{row.available_quantity}</Typography>
                            </StyledTableCell>
                            </StyledTableRow>
                        ))}
                        {(is_loading_inventory_history===true) &&
                        <StyledTableCell component="th" scope="row" colSpan={5} sx={{textAlign:'center'}}>
                            Please wait while loading the list...
                        </StyledTableCell>
                        }   
                        {(is_loading_inventory_history===false && inventory_history_list && inventory_history_list.length===0) &&
                        <StyledTableCell component="th" scope="row" colSpan={5} sx={{textAlign:'center'}}>
                            Oops ! No Book(s) Found
                        </StyledTableCell>
                        }   
                        </TableBody>
                    </Table>
                </TableContainer>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button size='small' onClick={handleCloseHistory}>Close</Button>
        </DialogActions>
        </Dialog>
        }
    </div>
  );
}
export default SchoolInventory;