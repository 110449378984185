import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from "react-redux";
import { Button, ButtonGroup, Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Textarea } from '@mui/joy';
import PrintHeader from '../PrintHeader';
import APP_HTTP from '../../APP_HTTP';
import EmployementForm from './EmployementForm';
import OfferLetter from './OfferLetter';
import StudentRegistrationForm from './StudentRegistrationForm';
import ParentForm from './ParentForm';

export default function FormsAndLetters() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  return (
    <Box sx={{ flexGrow: 1 }} className="MainContainer">
        <Grid container spacing={2} className="mrtop-10 mrbtm-30">
            <Grid item xs={12} sm={12} md={2} spacing={2}>
                <EmployementForm/>
                <StudentRegistrationForm/>
                <ParentForm/>
            </Grid>
            <Grid item xs={12} sm={12} md={7} spacing={2}>
                <OfferLetter />
            </Grid>
        </Grid>
    </Box>
  );
}