import * as React from 'react';
import { styled } from '@mui/material/styles';
import APP_HTTP from '../../../APP_HTTP.jsx';
import PropTypes from 'prop-types';
import { Alert, Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemIcon, ListItemText, TextField, Typography } from '@mui/material';
import { Check, CheckCircle, Download, Downloading, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';
import LoaderButton from '../../LoaderButton.jsx';
function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            sx={{ color: 'text.secondary' }}
          >
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }
  
  CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
  };
const StartNewSession = (props) => {
  
  const [message_list,setMessageList] = React.useState([
    {key:'back_dues',value:'Calculates Back Dues and will assign to the respective student.',is_checked:true,is_processed:false,is_processing:false,percent:0},
    {key:'payment_sequence',value:'Payment Sequence will reset and starts to 1 (i.e #PYI1,#PYO2).',is_checked:true,is_processed:false,is_processing:false,percent:0},
    {key:'homework',value:'Clear Homework History',is_checked:true,is_processed:false,is_processing:false,percent:0},
    {key:'fee_structure',value:'All student Fee Structure will reset and will automatically assign as per their respective class fee.',is_checked:true,is_processed:false,is_processing:false,percent:0},
    {key:'class_section_shifting',value:'Assign Next Class/Section to the student (i.e 1 to 2, 2 to 3 etc.)',is_checked:true,is_processed:false,is_processing:false,percent:0},
  ]);
  const [user_credential,setUserCredential] = React.useState({username:'',password:''});
  const [step,setStep] = React.useState(1);
  
  const [download_link,setDownloadLink] = React.useState('');
  const [is_open,setIsOpen] = React.useState(false);
  const [is_downloading,setIsDownloading] = React.useState(false);
  const [is_processing,setIsProcessing] = React.useState(false);
  const [show_success_check,setSuccessCheck] = React.useState(false);
  const [session_start_date,setSessionStartDate] = React.useState('');


  const initialized = React.useRef(false);
      React.useEffect(()=>{
          if (!initialized.current) {
              initialized.current = true;
             handleGetSettings();
          }
      },[])

  const handleSelectItem = (index,checked) =>{
    let msglist = [...message_list];
    msglist[index].is_checked = checked;
    setMessageList(msglist);
  }
  const handleProceed = (st) =>{
    if(st===4){
      APP_HTTP.REQUEST('session/authenticate',user_credential,true,true).then((response) => {
        
          if(response.data.success==1){
            setStep(st);
          }
      });
    }else{
      setStep(st);
    }
  }
  const handleChangeCredentials = (key,value) =>{
    let uc = {...user_credential};
    uc[key]=value;
    setUserCredential(uc);
  }
  const handleStartNewSession = () =>{
    processStartNewSession();
  }
  const processStartNewSession = () => {
    setIsProcessing(true);
    sendProcessRequestToServer( 'back_dues',1, 0, 0 );
    
  }
  const sendProcessRequestToServer = ( process_key, page, current_index, nop ) => {
    
    let msList = [...message_list];
    APP_HTTP.REQUEST('session/restart_new_session',{process_key:process_key,page:page,number_of_page:nop},true,true).then((response) => {
      if(response.data.success==1){
        let next_key = response.data.next_key;
        let is_processed = response.data.is_processed;
        current_index = response.data.current_index;
        msList[current_index].is_processed = is_processed;
        let cal_percent = 100;
        
        if( response.data.number_of_page){
          let npages = response.data.number_of_page;
          cal_percent = ( page / npages ) * 100;
          nop = npages;
        }
        msList[current_index].percent = Math.round(cal_percent);
        setMessageList(msList);
        if(is_processed===true && next_key!==false){
          current_index = current_index+1;
          sendProcessRequestToServer( next_key, 1, current_index, 0 );
        }else{
          page = page+1;
          sendProcessRequestToServer( next_key, page, current_index, nop );
        }
      }else{
        setSuccessCheck(true);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
  });
  }
  const handleGetSettings = () =>{
    APP_HTTP.REQUEST('settings/get_option',{name:'session_start_date'},true,true).then((response) => {
        let resp = response.data.response;
        if(resp.value && resp.value){
            setSessionStartDate(resp.value);
        }
    });
  }
  const handleClose = ()=>{
    setIsOpen(false);
  }
  const handleOpenSession = ()=>{
    setIsOpen(true);
  }
  const handleDownloadBackup = () =>{
    setIsDownloading(true);
    APP_HTTP.REQUEST('session/download_backup',{},true,true).then((response) => {
      if(response && response.data){
        let resp = response.data;
        if(resp.download){
          setDownloadLink(resp.download);
          setTimeout(() => {
            if(document.getElementById('handleDownload')){
              document.getElementById('handleDownload').click();
            }
            handleProceed(2);
            setIsDownloading(false);
          }, 1000);
        }else{
          handleProceed(2);
          setIsDownloading(false);
        }
      }else{
        handleProceed(2);
        setIsDownloading(false);
      }
  });
  }
  return (
    <>
        <Button variant='contained' color='success' onClick={handleOpenSession}>START A NEW SESSION</Button>
        {(session_start_date!=='') &&
        <Typography style={{color: 'red',fontSize: '14px',fontWeight: 'bold',fontFamily: 'monospace',letterSpacing: '1.5px'}}>LAST SESSION RESTARTED ON {session_start_date}</Typography>
        }
        <Dialog open={is_open} maxWidth='sm'>
        {(show_success_check===false  && is_processing===false) &&
            <DialogTitle style={{textAlign:'center'}}>
            {(step===1) &&
              <Typography>
                Don't Forget To Download Backup
              </Typography> 
            }
            {(step===2) &&
              <Typography>
                What will happen if you Restart a New Session
              </Typography> 
            } 
            {(step===3) &&
              <Typography>
                Authenticate Request
              </Typography> 
            } 
            {(step===4) &&
              <Typography>
                Process Request
              </Typography> 
            } 
            </DialogTitle>
        }
            <DialogContent>
              {(is_processing===true) &&
                <Alert severity="error" style={{marginBottom:'10px'}}>Please don't reload or close the tab until process completes.</Alert>
              }
              {(is_processing===false) &&
                <Alert severity="error" style={{marginBottom:'10px'}}><strong>Warning : </strong> Don't forget to set class sequence before proceeding to start new a session</Alert>
              }
              {(show_success_check===false) &&
                <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                    {(step===1) &&
                    <>
                      <Grid item xs={12} sm={12} md={12} spacing={2} style={{textAlign:'center'}}>
                        <Typography>
                          <Download style={{fontSize:'150px',cursor:'pointer',color:'#7b7bff'}}/>
                        </Typography>
                        <Typography style={{fontSize:'14px'}}>Here, You can download the backup of complete year Attendance, Homework, Marksheet</Typography>
                        {(is_downloading===false) &&
                          <Button variant='contained' color='success' style={{marginTop:'20px'}} onClick={()=>handleDownloadBackup()}><Downloading /> Start Downloading </Button>
                        }
                        {(download_link!=='') &&
                            <a href={download_link} download={download_link} id="handleDownload" style={{display:'none'}}>Download</a>
                        }
                        {(is_downloading===true) &&
                            <LoaderButton variant='contained' color='success' style={{marginTop:'20px'}} />
                        }
                      </Grid>
                    </>
                    }
                    {(step===2) &&
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                          <List dense={false}>
                          {(message_list.map((value,index)=>{
                            return(
                              <ListItem>
                                <ListItemIcon>
                                  <CheckCircle style={{color:'green'}}/>
                                </ListItemIcon>
                                <ListItemText
                                  primary={value.value}
                                />
                              </ListItem>
                             )
                            }))}
                          </List>
                           
                        </Grid>
                    }
                    {(step===3) &&
                    <>
                         <Grid item xs={12} sm={12} md={12} spacing={2}>
                            <TextField
                                label="Enter Username"
                                size="small"
                                fullWidth
                                onChange={(e)=>handleChangeCredentials('username',e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            <TextField
                                label="Enter Password"
                                size="small"
                                fullWidth
                                type='password'
                                onChange={(e)=>handleChangeCredentials('password',e.target.value)}
                            />
                        </Grid>
                    </>
                    }
                    {(step===4) &&
                    <>
                        {(message_list.map((value,index)=>{
                            return(
                                <>
                                {(value.is_checked===true) &&
                                <Grid item xs={12} sm={12} md={12} spacing={2}>
                                    {(value.is_processed===false) &&
                                    <Alert variant="outlined" severity="success" iconMapping={{
    success: <CircularProgressWithLabel value={value.percent} />
  }}>
                                    {value.value}
                                    </Alert>
                                    }
                                    {(value.is_processed===true) &&
                                    <Alert variant="outlined" severity="success" iconMapping={{
    success: <CheckCircle style={{color:'green'}}/>
  }}>
                                    {value.value}
                                    </Alert>
                                    }
                                </Grid>
                                }
                                </>
                            )
                        }))}
                        
                    </>
                    }
                </Grid>
              }
              {(show_success_check===true) &&
               <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                 <Grid item xs={12} sm={12} md={12} spacing={2} style={{textAlign:'center'}}>
                  <CheckCircle style={{fontSize:'150px', color:'#20b720'}}/>
                </Grid>
              </Grid>
              }
            </DialogContent>
            {(show_success_check===false) &&
            <DialogActions>
            {(step===1) &&
            <>
                <Button onClick={handleClose}>Close</Button>
                <Button variant='contained' color='error' onClick={()=>handleProceed(2)}>Next <KeyboardDoubleArrowRight/></Button>
            </>
            }
            {(step===2) &&
            <>
                <Button onClick={()=>handleProceed(1)}><KeyboardDoubleArrowLeft /> Back</Button>
                <Button variant='contained' color='error' onClick={()=>handleProceed(3)}>Next <KeyboardDoubleArrowRight/></Button>
            </>
            }
            {(step===3) &&
            <>
                <Button onClick={()=>handleProceed(2)}><KeyboardDoubleArrowLeft /> Back</Button>
                <Button variant='contained' color='error' onClick={()=>handleProceed(4)}>Authenticate <KeyboardDoubleArrowRight/></Button>
            </>
            }
            {(step===4) &&
            <>
            {(is_processing===false) &&
            <>
                <Button onClick={()=>handleProceed(3)}><KeyboardDoubleArrowLeft /> Back</Button>
                <Button variant='contained' color='error' onClick={()=>handleStartNewSession()}>Start A New Session</Button>
            </>
            }
            </>
            }
            </DialogActions>
            }
        </Dialog>
    </>
  );
}

export default StartNewSession;